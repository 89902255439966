<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form
        slot-scope="{
          validate
        }"
        @submit.prevent="validate().then((suc) => {if(suc) submetAllGoals()})"
      >
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>تفاصيل قسم "قيمنا"</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <div v-for="(value, index) in valuesDash" :key="index">
            <b-col cols="12">
              <h5> القيمة {{index + 1}}</h5>
              <label>صورة القيمة</label>
              <fileDragDrop
                @uploaded="uploadThumbImage($event, index)"
                :id="'value-photo' + index"
                height="100px"
                type="image"
                title="صورة جديدة"
              />
              <img
                v-if="!!value.imageDto.base64 || !!value.imageDto.path"
                :src="!value.imageDto.base64 ? value.imageDto.path.replace('~', $store.getters['app/domainHost']) : value.imageDto.base64"
                style="max-height: 320px; object-fit: fill;"
                class="w-100"
              />
              <small class="text-danger" v-show="!(value.imageDto.base64 || value.imageDto.id)">صورة  القسم مطلوبة</small>
            </b-col>
            <b-col cols="12">
              <b-form-group label='نص "القيمة"'>
                <b-form-textarea v-model="value.description"></b-form-textarea>
              </b-form-group>
            </b-col>
            <hr />
          </div>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
                variant="outline-primary"
                class="ml-auto mr-1"
                @click="is = false"
              >إلغاء</b-button>
              <b-button
                variant="primary"
                type="submit"
              >تم</b-button>
            </div>
          </template> 
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
import { mapGetters, mapActions } from 'vuex';
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  export default {
    components: {
      ValidationObserver,
      fileDragDrop
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters(["valuesDash"]),
      is: {
        get() {
          return this.$store.getters["isValuesModal"];
        },
        set(is) {
          this.$store.dispatch("setIsValuesModal", is);
        },
      },
    },
    methods: {
      ...mapActions(["updateAbout"]),
      submetAllGoals() {
        this.valuesDash.forEach(value => {
          this.updateAbout(value)
        });
      },
      uploadThumbImage(file, i) {
          this.valuesDash[i].imageDto.base64 = file[0].base64;
      }
    }
  };
</script>
