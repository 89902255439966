<template>
  <div>
    <b-button-group class="mb-1">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        to="/admin/company-info/0"
        >عربي</b-button
      >
      <b-button
        to="/admin/company-info/1"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="overflow-hidden">
          <b-card-header class="p-1 justify-content-center">
            <h5 class="m-0">
              <strong>من نحن</strong>
            </h5>
          </b-card-header>
          <b-card-body class="px-1 pb-1">
            <b-img
              block
              height="140"
              rounded
              style="object-fit: cover;"
              class="w-100"
              alt="Image 1"
              :src="aboutusDash.imageDto.path ? aboutusDash.imageDto.path.replace(
                    '~',
                    $store.getters['app/domainHost']
                  ) : ''
                "
            ></b-img>
            <p class="m-0 pt-50">{{aboutusDash.description}}</p>
          </b-card-body>
          <b-card-footer class="p-0">
            <b-button variant="primary" @click="setIsAboutModal(true)" block squared>عرض التفاصيل</b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6">
        <b-card no-body class="overflow-hidden">
          <b-card-header class="p-1 justify-content-center">
            <h5 class="m-0">
              <strong>أهدافنا</strong>
            </h5>
          </b-card-header>
          <b-card-body class="px-1 pb-1">
            <div v-for="(goal, index) in goalsDash" :key="index">
              <b-img
                block
                height="140"
                rounded
                style="object-fit: cover;"
                class="w-100"
                alt="Image 1"
                :src="
                    goal.imageDto.path ? goal.imageDto.path.replace(
                      '~',
                      $store.getters['app/domainHost']
                    ) : ''
                  "
              ></b-img>
              <p>{{goal.description}}</p>
            </div>
          </b-card-body>
          <b-card-footer class="p-0">
            <b-button variant="primary" @click="setIsGoalsModal(true)" block squared>عرض التفاصيل</b-button>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6">
        <b-card no-body class="overflow-hidden">
          <b-card-header class="p-1 justify-content-center">
            <h5 class="m-0">
              <strong>قيمنا</strong>
            </h5>
          </b-card-header>
          <b-card-body class="px-1 pb-1">
            <div v-for="(value, index) in valuesDash" :key="index">
              <b-img
                block
                height="140"
                rounded
                style="object-fit: cover;"
                class="w-100"
                alt="Image 1"
                :src="
                    value.imageDto.path ? value.imageDto.path.replace(
                      '~',
                      $store.getters['app/domainHost']
                    ) : ''
                  "
              ></b-img>
              <p>{{value.description}}</p>
            </div>
          </b-card-body>
          <b-card-footer class="p-0">
            <b-button variant="primary" @click="setIsValuesModal(true)" block squared>عرض التفاصيل</b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <aboutModal />
    <goalsModal />
    <valuesModal />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import aboutModal from './components/about-modal.vue';
import goalsModal from './components/goals-modal.vue';
import valuesModal from './components/values-modal.vue';
  export default {
    components: {
      aboutModal,
      goalsModal,
      valuesModal
    },
    props: {
      lang: String,
    },
    computed: {
      ...mapGetters(['aboutusDash', 'valuesDash', 'goalsDash'])
    },
    created() {
      this.getAboutPage(!+this.lang ? 1 : 2)
    },
    methods: {
      ...mapActions(['getAboutPage', "setIsAboutModal", "setIsGoalsModal", "setIsValuesModal"])
    },
    watch: {
      lang(lang) {
        this.getAboutPage(!+lang ? 1 : 2)
      }
    }
  };
</script>
