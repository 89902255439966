var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button-group',{staticClass:"mb-1"},[_c('b-button',{attrs:{"variant":!+_vm.lang ? 'primary' : 'outline-primary',"to":"/admin/company-info/0"}},[_vm._v("عربي")]),_c('b-button',{attrs:{"to":"/admin/company-info/1","variant":!+_vm.lang ? 'outline-primary' : 'primary'}},[_vm._v("English")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1 justify-content-center"},[_c('h5',{staticClass:"m-0"},[_c('strong',[_vm._v("من نحن")])])]),_c('b-card-body',{staticClass:"px-1 pb-1"},[_c('b-img',{staticClass:"w-100",staticStyle:{"object-fit":"cover"},attrs:{"block":"","height":"140","rounded":"","alt":"Image 1","src":_vm.aboutusDash.imageDto.path ? _vm.aboutusDash.imageDto.path.replace(
                  '~',
                  _vm.$store.getters['app/domainHost']
                ) : ''}}),_c('p',{staticClass:"m-0 pt-50"},[_vm._v(_vm._s(_vm.aboutusDash.description))])],1),_c('b-card-footer',{staticClass:"p-0"},[_c('b-button',{attrs:{"variant":"primary","block":"","squared":""},on:{"click":function($event){return _vm.setIsAboutModal(true)}}},[_vm._v("عرض التفاصيل")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1 justify-content-center"},[_c('h5',{staticClass:"m-0"},[_c('strong',[_vm._v("أهدافنا")])])]),_c('b-card-body',{staticClass:"px-1 pb-1"},_vm._l((_vm.goalsDash),function(goal,index){return _c('div',{key:index},[_c('b-img',{staticClass:"w-100",staticStyle:{"object-fit":"cover"},attrs:{"block":"","height":"140","rounded":"","alt":"Image 1","src":goal.imageDto.path ? goal.imageDto.path.replace(
                    '~',
                    _vm.$store.getters['app/domainHost']
                  ) : ''}}),_c('p',[_vm._v(_vm._s(goal.description))])],1)}),0),_c('b-card-footer',{staticClass:"p-0"},[_c('b-button',{attrs:{"variant":"primary","block":"","squared":""},on:{"click":function($event){return _vm.setIsGoalsModal(true)}}},[_vm._v("عرض التفاصيل")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1 justify-content-center"},[_c('h5',{staticClass:"m-0"},[_c('strong',[_vm._v("قيمنا")])])]),_c('b-card-body',{staticClass:"px-1 pb-1"},_vm._l((_vm.valuesDash),function(value,index){return _c('div',{key:index},[_c('b-img',{staticClass:"w-100",staticStyle:{"object-fit":"cover"},attrs:{"block":"","height":"140","rounded":"","alt":"Image 1","src":value.imageDto.path ? value.imageDto.path.replace(
                    '~',
                    _vm.$store.getters['app/domainHost']
                  ) : ''}}),_c('p',[_vm._v(_vm._s(value.description))])],1)}),0),_c('b-card-footer',{staticClass:"p-0"},[_c('b-button',{attrs:{"variant":"primary","block":"","squared":""},on:{"click":function($event){return _vm.setIsValuesModal(true)}}},[_vm._v("عرض التفاصيل")])],1)],1)],1)],1),_c('aboutModal'),_c('goalsModal'),_c('valuesModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }