<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form
        slot-scope="{
          validate
        }"
        @submit.prevent="validate().then((suc) => {if(suc) updateAbout(aboutusDash)})"
      >
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>تفاصيل قسم "من نحن"</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col cols="12">
            <label>صورة القسم</label>
            <fileDragDrop
              @uploaded="uploadThumbImage"
              id="about-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!aboutusDash.imageDto.base64 || !!aboutusDash.imageDto.path"
              :src="!aboutusDash.imageDto.base64 ? aboutusDash.imageDto.path.replace('~', $store.getters['app/domainHost']) : aboutusDash.imageDto.base64"
              style="max-height: 320px; object-fit: fill;"
              class="w-100"
            />
            <small class="text-danger" v-show="!(aboutusDash.imageDto.base64 || aboutusDash.imageDto.id)">صورة  القسم مطلوبة</small>
          </b-col>
          <b-col cols="12">
            <b-form-group label='نص "من نحن"'>
              <b-form-textarea v-model="aboutusDash.description"></b-form-textarea>
            </b-form-group>
          </b-col>
            
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
                variant="outline-primary"
                class="ml-auto mr-1"
                @click="is = false"
              >إلغاء</b-button>
              <b-button
                variant="primary"
                type="submit"
              >تم</b-button>
            </div>
          </template> 
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { mapGetters, mapActions } from 'vuex';
  export default {
    components: {
      ValidationObserver,
      fileDragDrop
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters(["aboutusDash"]),
      is: {
        get() {
          return this.$store.getters["isAboutModal"];
        },
        set(is) {
          this.$store.dispatch("setIsAboutModal", is);
        },
      },
    },
    methods: {
      ...mapActions(["updateAbout"]),
      uploadThumbImage(file) {
          this.aboutusDash.imageDto.base64 = file[0].base64;
      }
    }
  };
</script>
